import React, {useEffect} from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// import HomePageComponent from '../Templates/HomePageComponent';

const JobbioSearchPage = () => {
  useEffect(() => {

  });

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        <script src="../../jobbio/js/lightbox.js"></script>
        <script src="../../jobbio/js/swiper.js"></script>
        <script src="../../jobbio/js/smooth-scroll.polyfills.min.js"></script>
        <script src="https://widgets.jobbio.com/partner_fluid_v1.3/search.js"
          id="partner-search-script"></script>
      </Helmet>
      <Helmet>
        <script>{`
    $(document).ready(function () {
      setTimeout(function () {
        partner_search.widget({
          slug: 'shaw-academy-careers',
          applicationUrl: 'https://www.shawacademy.com/shawcareers/',
          googleAutoCompleteKey: 'AIzaSyD_HUZh2pQwtuG9P4mSz3rp8El-J8J99TQ',
        })
      }, 1000)
    });`}
        </script>
      </Helmet>
      <Helmet>
        {`<script>
      $(document).ready(function () {
        setTimeout(function () {
          $("#menu-logo-dark2").attr("src", "https://dt9ph4xofvj87.cloudfront.net/logo-new.svg");
        }, 1000)
      });
    </script>`}
      </Helmet>
    </>
  );
};

export default JobbioSearchPage;
